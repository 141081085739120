::-webkit-scrollbar {
    width: 6px;
    /* for vertical scrollbars */
    height: 6px;
    /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
    background: rgba(35, 31, 32, 0.1);
}

::-webkit-scrollbar-thumb {
    background: rgba(35, 31, 32, 0.5);
}

.scrollbar-invisible::-webkit-scrollbar-track {
    background: none;
}

.scrollbar-invisible::-webkit-scrollbar-thumb {
    background: none;
}

.table-responsive::-webkit-scrollbar-track {
    background: none;
}

.table-responsive::-webkit-scrollbar-thumb {
    background: none;
}

body {
    margin: 0;
    font-family: 'CamptonLight', sans-serif;
    font-size: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f3f3f3;
}

b {
    font-family: 'CamptonBold', sans-serif;
}

div, select {
    font-family: 'CamptonLight', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video {
    min-height: 400px !important;
}

.container-min-height {
    min-height: calc(100vh - 180px);
}

.cursor-pointer {
    cursor: pointer !important;
}

.my-rounded {
    border-radius: 8px !important;
}

.my-rounded-top {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}

.notification-icon {
    border-radius: 50%;
    border: 2px solid #9c999f;
}

.notification-icon.active {
    border-radius: 50%;
    border: 2px solid #f9beaa;
    background-color: #334cd1;
}

.notification-icon.active img {
    content: url("./assets/images/notification-white.svg");
}

.color-accent-lazy {
    color: #334cd1;
}

.color-accent, .spinner-border.text-warning {
    color: #334cd1 !important;
}

a.color-accent-hover:hover, .color-accent-hover:hover {
    color: #334cd1 !important;
}

.color-white-hover:hover {
    color: #ffffff !important;
}

.background-accent {
    background-color: #334cd1 !important;
}

.background-accent-light {
    background-color: #f9beaa !important;
}

.background-accent-hover:hover {
    background-color: #334cd1 !important;
}

.border-accent {
    border-color: #f9beaa !important;
}

.border-radius-standard {
    border-radius: .25rem !important;
}

.border-radius-10 {
    border-radius: 10px;
}

.border-radius-30 {
    border-radius: 30px;
}

.my-nav-item {
    margin-bottom: 30px;
}

.my-nav-item a {
    color: #5b5a5a !important;
}

.my-nav-item a:hover {
    color: #334cd1 !important;
}

.my-nav-item a.active {
    color: #334cd1 !important;
    background-color: #ffffff !important;
}

.my-tab-nav-item a {
    padding: 6px 15px 6px 15px;
    color: #334cd1 !important;
    font-weight: bold;
}

.my-tab-nav-item a:hover {
    color: #334cd1 !important;
}

.my-tab-nav-item a.active {
    color: #334cd1 !important;
    background-color: #ffffff !important;
    box-shadow: 0 1px 2px 0 rgba(191, 65, 3, 0.75) !important;
}

.my-pt-1 {
    padding-top: .15rem !important
}

.my-p-0-8 {
    padding: .12rem !important;
}

.my-p-0-9 {
    padding: .20rem !important;
}

.mt-36 {
    margin-top: 36px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-30 {
    font-size: 35px;
}

.min-width-80-per {
    min-width: 80% !important;
}

.size-40 {
    width: 40px !important;
    height: 40px !important;
}

.min-width-55-per {
    min-width: 55% !important;
}

.w-30 {
    width: 28% !important;
}

.modal-content .react-transform-component {
    width: 100% !important;
}

.modal-content .react-transform-element {
    width: 100% !important;
    position: relative;
}

.min-width-160 {
    min-width: 160px !important;
}

.min-width-160 {
    min-width: 160px !important;
}

.min-height-60 {
    min-height: 60px !important;
}

.min-height-55 {
    min-height: 55px !important;
}

.min-height-48 {
    min-height: 48px !important;
}

.gender-check-box-container {
    width: 120px;
    position: relative;
    margin: auto;
    cursor: pointer;
}

.gender-check-box-container img, .gender-check-box-container p {
    margin-left: -20px;
}

.gender-check-box {
    width: 30px;
    height: 30px;
    background-color: #e3d4d4;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 10px;
}

.gender-check-box img {
    position: relative;
    margin: auto;
    top: 1px;
}

.PhoneInput > input {
    height: 100%;
    -webkit-box-shadow: 0 0 0 60px #ffffff inset !important;
}

.PhoneInput > input, .PhoneInput > input:focus, .PhoneInput > input:active,
.input-transparent, .input-transparent:focus, .input-transparent:active {
    background: none !important;
    border: none !important;
    outline: none;
}

.btn-primary, .swal-button--confirm {
    background-color: #334cd1 !important;
    border-color: #233dce !important;
    border-radius: 8px;
    color: #ffffff !important
}

.btn-outline-primary {
    border-color: #334cd1 !important;
}

.btn-outline-primary:hover {
    background-color: #334cd1 !important;
    color: #ffffff !important;
}

.btn-outline-primary:hover div {
    color: #ffffff !important;
}

.google-sign-in-btn:hover img {
    content: url("./assets/images/google-glass-logo-white.svg");
}

.btn-primary.sm {
    height: 30px !important;
}

.btn-primary:hover, .swal-button--confirm:hover {
    background-color: #233dce !important;
    border-color: #233dce !important;
}

.btn-primary:focus, .btn-primary:active, .btn-outline-primary:focus, .btn-outline-primary:active, .swal-button--confirm:focus, .swal-button--confirm:active {
    box-shadow: 0 -1px 6px 0 rgb(35, 61, 206) !important;
}

.pagination-btn-primary a {
    color: #233dce !important
}

.pagination-btn-primary.active a {
    background-color: #334cd1 !important;
    border-color: #233dce !important;
    color: #ffffff !important
}

.pagination-btn-primary.active a:hover {
    background-color: #233dce !important;
    border-color: #233dce !important;
}

.pagination-btn-primary a:focus, .pagination-btn-primary a:active {
    box-shadow: 0 -1px 6px 0 rgba(191, 65, 3, 0.75) !important;
}

.badge-danger {
    padding: .3rem;
    border: 1px solid #FAC7CF !important;
    color: #f55f78 !important;
    background-color: #FACAD2 !important;
}

.badge-info {
    padding: .3rem;
    border: 1px solid #a2d6fd !important;
    color: #36a3f7 !important;
    background-color: #a6d8ff !important;
}

.badge-pending {
    padding: .3rem;
    border: 1px solid #C1C1C1 !important;
    color: #6C6C6C !important;
    background-color: #CCCCCC !important;
}

.loan-return {
    color: #28a745 !important;
    background-color: #e9e8e8;
}

.loan-amounts {
    color: #334cd1 !important;
    background-color: #e9e8e8;
}

.badge-success {
    padding: .3rem;
    border: 1px solid #AFDDBE !important;
    color: #199739 !important;
    background-color: #b6dfc3 !important;
}

.badge-warning {
    padding: .3rem;
    border: 1px solid #FAE0B6 !important;
    color: #eaaa75 !important;
    background-color: #fce4bd !important;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:active,
textarea:-webkit-autofill:focus,
input:not(input[type='range']):not(.PhoneInputInput):-webkit-autofill,
input:not(input[type='range']):not(.PhoneInputInput):-webkit-autofill:hover,
input:not(input[type='range']):not(.PhoneInputInput):-webkit-autofill:active,
input:not(input[type='range']):not(.PhoneInputInput):-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 60px rgba(191, 65, 3, 0.1) inset !important;
}

textarea {
    width: 100% !important;
    border-color: #e2e0df !important;
    box-shadow: 0 1px 3px 3px rgba(226, 224, 223, 0.2) !important;
}

input[data-id] {
    width: calc((100% - 40px) / 5) !important;
    max-width: 55px;
    height: 55px !important;
    outline: none !important;
    text-align: center;
    padding: 0 !important;
}

input:not(input[type='range']):not(.input-transparent):not(.PhoneInputInput):not(input[data-id]) {
    height: 50px !important;
    background-color: #ffffff !important;
    box-shadow: 0 1px 3px 3px rgba(226, 224, 223, 0.2) !important;
}

input:not(input[type='range']):not(.input-transparent):not(.PhoneInputInput):not(input[data-id]):focus {
    border-color: #e2e0df !important;
}

input:not(input[type='range']):not(.input-transparent):not(.PhoneInputInput):disabled,
input:not(input[type='range']):not(.input-transparent):not(.PhoneInputInput):read-only {
    background-color: #f1efee !important;
    cursor: not-allowed;
}

button:disabled {
    cursor: not-allowed;
}

input:not(input[type='range']).sm, select.sm, .custom-select-sm {
    height: 30px !important;
}

.custom-select-sm {
    height: 30px !important;
    font-size: 12px !important;
}

textarea:focus,
textarea:active,
input:not(input[type='range']):not(.PhoneInputInput):focus,
input:not(input[type='range']):not(.PhoneInputInput):active,
select:focus, select:active {
    box-shadow: 0 1px 3px 3px rgba(191, 65, 3, 0.1) !important;
}

select {
    box-shadow: 0 1px 3px 3px rgba(226, 224, 223, 0.2) !important;
}

select:focus {
    border-color: #e2e0df !important;
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: 48px;
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-image: url("./assets/images/arrows.svg");
    background-size: 8px;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.custom-input-amount {
    background-repeat: no-repeat;
    padding-left: 40px;
    background-position: left .75rem center;
    background-image: url("./assets/images/naira-sign-dark.svg");
    background-size: 20px;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

a.navbar-brand {
    color: #334cd1;
}

.navbar-brand:hover {
    color: #233dce;
}

.mobile-bottom-sheet-container {
    display: none;
}

.mobile-bottom-sheet {
    display: none;
}

.mobile-header-menu {
    display: none;
}

.loan-type-btn-aligner {
    text-align: right;
}

.underline {
    border-bottom: 1px solid #e9e8e8;
}

.underline-dotted {
    border-bottom: 1px dashed #e9e8e8;
}

.underline-children > div {
    border-bottom: 1px solid #e9e8e8;
}

.underline-grandchildren > div > div {
    border-bottom: 1px solid #e9e8e8;
}

.loam-amount {
    font-size: 35px;
    margin-top: -5px !important;
}

.item-center {
    position: absolute;
    margin: auto;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
}

@media only screen and (max-width: 991px) {

    .loam-amount {
        margin-top: 0 !important;
        font-size: 22px;
        padding-top: 20px;
    }

    /* For mobile phones: */
    .mobile-header-menu {
        display: block;
    }

    .pc-side-nav, .pc-brand, .pc-header-menu {
        display: none;
    }

    .pc-container {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .font-size-13 {
        font-size: 13px !important;
    }

    .mobile-bottom-sheet-container {
        z-index: 10000;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transition: all ease 0.8s;
    }

    .mobile-bottom-sheet-container.top {
        display: block;
        background-color: #000000;
        opacity: .5;
    }

    .mobile-bottom-sheet {
        width: 100%;
        max-height: 80vh;
        background-color: #ffffff;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        overflow: hidden;
        z-index: 10001;
        display: block;
        position: fixed;
        bottom: -1000px;
        transition: all ease 0.5s;
        box-shadow: 1px -5px 5px 1px rgba(0, 0, 0, 0.10);
    }

    .mobile-bottom-sheet .sheet-line {
        background-color: #ffffff;
        width: 100%;
        height: 40px;
    }

    .sheet-line div {
        width: 40px;
        height: 4px;
        margin-top: 20px;
        border-radius: 5px;
        background-color: #C1C1C1;
    }

    .mobile-bottom-sheet .sheet-holder {
        width: 100%;
        max-height: calc(80vh - 40px);
        padding-left: 20px;
        padding-bottom: 10px;
    }

    .mobile-bottom-sheet .my-nav-item {
        margin-bottom: 0 !important;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .mobile-bottom-sheet .my-nav-item:first-child {
        padding-top: 0 !important;
    }

    .mobile-bottom-sheet .my-nav-item:not(:last-child) {
        border-bottom: 1px solid #e9e8e8;
    }

    .mobile-bottom-sheet.top {
        bottom: 0;
    }

    .loan-type-btn-aligner {
        text-align: center;
    }
}

.scroll-vertical {
    overflow-x: hidden !important;
    overflow-y: scroll !important;
}

.scroll-horizontal {
    overflow-x: scroll !important;
    overflow-y: hidden !important;
}

.scroll-both {
    overflow: scroll !important;
}

.swal-text {
    font-size: 20px !important;
    text-align: center !important;
}

.dropdown-item.active, .dropdown-item:active, .dropdown-item:focus {
    color: #ffffff;
    background-color: #334cd1;
}

.dropdown-toggle:after {
    display: none;
}

.no-shadow:focus,
.no-shadow:active {
    box-shadow: none;
}

.single-line-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-arrow {
    background-image: url("./assets/images/arrow.svg");
    background-size: 6px;
    background-repeat: no-repeat;
    background-position: right 6px;
}

.pass-wrapper {
    position: relative;
}

.pass-wrapper input {
    padding-right: 50px;
}

.pass-wrapper i {
    position: absolute;
    width: 50px;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 12px;
    text-align: center;
    color: #bbbec9;
    font-size: 20px;
    cursor: pointer;
}
